/** @jsx jsx */
import { Box, Container, jsx, Text, Grid, Flex } from 'theme-ui'
import { FC, useState } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import * as R from 'ramda'
import { Close } from '~/components/icons/close'
import useMediaQuery from '~/hooks/use-media-query'
import { Checkbox } from '~/components/checkbox'
import CollectionLayout from '~/components/layout/collection-layout'
import urls from '~/common/urls'

type Props = {}

export const ShopByRoom: FC<Props> = ({ data }) => {
  const [roomsFilter, setRoomsFilter] = useState<string[]>([])
  const [stylesFilter, setStylesFilter] = useState<string[]>([])
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([])

  const allRooms = R.uniqWith(
    R.equals,
    data.allMarkdownRemark.edges.map(x => x.node.frontmatter.rooms)
  )
  const allStyles = R.uniqWith(
    R.equals,
    data.allMarkdownRemark.edges.map(x => x.node.frontmatter.styles)
  )
  const allCategories = R.uniqWith(
    R.equals,
    data.allMarkdownRemark.edges.map(x => x.node.frontmatter.categories)
  )

  const handleResetFilters = () => {
    setRoomsFilter([])
    setStylesFilter([])
    setCategoriesFilter([])
  }

  const handleFilterChange = (filter: string, item: any) => {
    const { name } = item
    const collection =
      filter === 'Rooms' ? roomsFilter : filter === 'Styles' ? stylesFilter : categoriesFilter
    const setter =
      filter === 'Rooms'
        ? setRoomsFilter
        : filter === 'Styles'
        ? setStylesFilter
        : setCategoriesFilter
    const exists = collection.find(selectedItem => selectedItem === name)

    if (!exists) {
      setter(() => [...collection, name])
    } else {
      setter(() => collection.filter(selectedItem => selectedItem !== name))
    }
  }

  const isChecked = (filter: string, item: any) => {
    if (filter === 'Rooms') {
      return roomsFilter.indexOf(item) >= 0
    } else if (filter === 'Styles') {
      return stylesFilter.indexOf(item) >= 0
    } else {
      return categoriesFilter.indexOf(item) >= 0
    }
  }

  const splitByCapitalLetters = (text: string) => {
    return text
      .split(/([A-Z][a-z]*)/u)
      .filter(part => part.length > 0)
      .join(' ')
  }

  const mapFilterItems = (data: readonly string[]) => {
    return data.map(r => ({
      name: splitByCapitalLetters(r),
      value: r,
    }))
  }

  const filtersData = [
    {
      order: 1,
      name: 'Rooms',
      items: mapFilterItems(allRooms[0]),
    },
    {
      order: 2,
      name: 'Styles',
      items: mapFilterItems(allStyles[0]),
    },
    {
      order: 3,
      name: 'Categories',
      items: mapFilterItems(allCategories[0]),
    },
  ]

  const isDesktopOrLaptop = useMediaQuery({
    query: breakpoints => `(min-device-width: ${breakpoints[1]})`,
  })

  const filteredRooms = data.allMarkdownRemark.edges
    .filter(room =>
      roomsFilter.length > 0
        ? room.node.frontmatter.rooms.some(x => roomsFilter.some(y => y === x))
        : true
    )
    .filter(room =>
      stylesFilter.length > 0
        ? room.node.frontmatter.styles.some(x => stylesFilter.some(y => y === x))
        : true
    )
    .filter(room =>
      categoriesFilter.length > 0
        ? room.node.frontmatter.categories.some(x => categoriesFilter.some(y => y === x))
        : true
    )

  return (
    <CollectionLayout
      title="Shop by room"
      description="Get inspired with room decorating ideas prepared by our experienced designers. Reinvent your space with a fresh design ideas."
    >
      <Container>
        {(roomsFilter.length > 0 || stylesFilter.length > 0 || categoriesFilter.length > 0) && (
          <Flex
            sx={{
              mb: 'l2',
            }}
          >
            <Box
              sx={{
                justifyContent: ['center', null, 'flex-end'],
                borderBottomStyle: [null, null, 'solid'],
                borderBottomWidth: [null, null, '1px'],
                borderBottomColor: 'border',
              }}
            >
              <button
                sx={{
                  color: 'text',
                  backgroundColor: 'transparent',
                  border: 'none',
                  fontSize: '18px',
                }}
                onClick={handleResetFilters}
              >
                Clear Filter
              </button>
            </Box>
          </Flex>
        )}
        <Flex>
          {filtersData.map(filter => (
            <Box
              sx={{
                width: ['100%', null, 250],
                borderStyle: ['solid', null, 'unset'],
                borderWidth: ['1px 0', null, 'unset'],
                borderColor: ['border', null, 'unset'],
                mb: ['-1px', null, null, 'inherit'],
              }}
            >
              <Text
                as={isDesktopOrLaptop ? 'h3' : 'button'}
                variant="label"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                  border: 0,
                  m: 0,
                  mb: [0, null, null, '28px'],
                  height: [80, null, 'auto'],
                  width: '100%',
                }}
              >
                {filter.name}
                {!isDesktopOrLaptop && <Close />}
              </Text>
              <Box
                sx={{
                  pb: [40, null, null, 0],
                }}
              >
                {filter.items.map(item => (
                  <Checkbox
                    label={item.name}
                    key={item.value}
                    onChange={() => handleFilterChange(filter.name, item)}
                    checked={isChecked(filter.name, item.value)}
                    value={isChecked(filter.name, item.value)}
                  />
                ))}
              </Box>
            </Box>
          ))}
        </Flex>
        <Grid
          columns={['1fr', null, '1fr 1fr']}
          sx={{
            mt: '87px',
          }}
          gap={32}
        >
          {filteredRooms.map(e => (
            <Link
              to={`${urls.pages.basePages}/${e.node.fields.slug}`}
              key={e.node.frontmatter.title}
            >
              <Box>
                <Img fluid={e.node.frontmatter.roomImage.childImageSharp.fluid} />
              </Box>
            </Link>
          ))}
        </Grid>
      </Container>
    </CollectionLayout>
  )
}

/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import { graphql } from 'gatsby'
import { ShopByRoom } from '~/templates/shop-by-room'

export const shopByRoomPageQuery = graphql`
  query shopByRoomPageQuery {
    allMarkdownRemark(filter: { fields: { sourceName: { eq: "shopByRoom" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            roomImage {
              ...heroImage
            }
            rooms
            styles
            categories
            products {
              handle
            }
          }
          html
          ...markdownFields
        }
      }
    }
  }
`

const ShopByRoomPage: FC = props => <ShopByRoom {...props} />

export default ShopByRoomPage
